
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import { getEncList, deleteEnc, getEncCourse } from '@/api/index'
import CreateEnc from './components/createEnc.vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import CourseDialog from '../../components/dialog-table.vue'
import { useRouter } from 'vue-router'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import { message, Empty } from 'ant-design-vue'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '百科列表'
  },
  {
    id: 2,
    btnName: '新建百科'
  }
]
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '百科名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '百科标签',
    dataIndex: 'tag_str',
    key: 'tag_str'
  },
  {
    title: '百科备注',
    dataIndex: 'remarks',
    key: 'remarks'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right',
    width: 400
  }
]
const courseColums = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '课程名称',
    dataIndex: 'name',
    key: 'name'
  }
]
interface ListProps {
  search: string; // 搜索关键字(含对学校名，联系人，联系人手机）
  page: number;
  pagesize: number;
  tags?: string; // 选中的标签
  is_asc?: boolean; // 是否正序，T正 F反
}
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'EncyclopediasManger',
  components: {
    mangerPageHeader,
    CreateEnc,
    CourseDialog
  },
  setup () {
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination }
    })
    const courseData = ref([])
    const searchInput = ref('')
    // 获取百科列表
    const fetchEncHttp = (isDelete?: boolean) => {
      getEncList({
        search: searchInput.value.trim(),
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then((res) => {
        tableData.data = res.data.map((item: { key: number; id: number }) => {
          item.key = item.id
          return item
        })
        tableData.pagination.total = res.result ? res.result : 0
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetchEncHttp()
        }
      })
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetchEncHttp()
    }
    const changeData = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      fetchEncHttp()
    }

    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchEncHttp)

    // 删除百科
    const deleteItem = (id: number) => {
      showConfirm({
        content: '此操作不可逆,确定要删除该百科吗?',
        callBack: () => {
          deleteEnc({
            id
          }).then(res => {
            message.success('删除百科成功')
            fetchEncHttp(true)
          })
        }
      })
    }
    // 获取课程
    const fetchCourseHttp = (id: number) => {
      getEncCourse({
        encyclopedia_id: id
      }).then(res => {
        courseData.value = res.data.map(
          (item: any) => {
            item.key = item.id
            return item
          })
      })
    }
    // 打开所属课程弹框
    const courseDialogVisible = ref<boolean>(false)
    const dialogRef = ref()
    const openCourse = (id: number) => {
      courseDialogVisible.value = true
      // const { fetchCourseHttp } = dialogRef.value
      fetchCourseHttp(id)
    }

    // 关闭所属课程弹框
    const dialogCancle = () => {
      courseDialogVisible.value = false
    }

    // 跳转编辑页面
    const router = useRouter()
    const goEditor = (id: number) => {
      router.push({ name: 'CreateEnc', params: { id } })
    }
    // 跳转知识点创建与编辑
    const handlePoint = (id: number) => {
      router.push({ name: 'CreatePoints', params: { id } })
    }
    onMounted(() => {
      fetchEncHttp()
    })
    return {
      courseData,
      courseColums,
      size: ref('small'),
      btnList,
      ...toRefs(tableData),
      searchInput,
      fetchSearch,
      changeData,
      changeIndex,
      btnIndex,
      deleteItem,
      openCourse,
      courseDialogVisible,
      dialogCancle,
      dialogRef,
      goEditor,
      handlePoint
    }
  }
})
